<template>
    <div class="p-5 mx-3 mt-1 bg-white rounded-lg">
        <div class="flex">
            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
            <div class="flex items-center flex-1">
                <h5 class="font-bold">預訂時長</h5>
                <div class="ml-3 text-xs text-gray-400">
                    至多預訂時數 {{ bookingMaxHour }}
                </div>
            </div>
        </div>
        <div class="flex items-center mt-5">
            <!-- <div class="text-gray-400">每小時</div> -->
            <!-- <div class="flex-1 mr-2 text-right OpenSansFont">${{categoryPrice | formatCurrency}}</div> -->
            <div class="flex items-center OpenSansFont">
                <button
                    :disabled="categoryDefaultLimitHour >= form.duration"
                    class="flex items-center justify-center w-5 h-5 border border-gray-300 rounded cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white"
                    @click.prevent="chagneTime(false)"
                >
                    -
                </button>
                <!-- <span class="mx-5">{{ form.duration }}</span> -->
                <input
                    v-model.number="form.duration"
                    type="number"
                    class="text-center w-[80px] mx-5"
                    @input="inputTime"
                    @blur="inputBlurTime"
                />
                <button
                    :disabled="bookingMaxHour <= form.duration"
                    class="flex items-center justify-center w-5 h-5 text-red-500 border border-red-500 rounded cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white"
                    @click.prevent="chagneTime(true)"
                >
                    +
                </button>
                <span class="ml-5">小時</span>
            </div>
        </div>
    </div>
</template>
<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import store from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  // 活動每小時單價
  categoryPrice: {
    type: Number,
    required: true
  },
  // 活動最少預訂時數
  categoryDefaultLimitHour: {
    type: Number,
    required: true
  },
  // 活動最長可預訂時數
  bookingMaxHour: {
    type: Number,
    required: true
  },
  // 活動預訂時常
  bookingDuration: {
    type: Number
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const form = ref({
    duration: 0
  });
  const emit = __ctx.emit;
  /**
   * 預訂時數新增或減少
   * @param { type Boolean(布林) } isAdd 判斷是否增加時常
   */
  function chagneTime(isAdd = false) {
    if (isAdd) {
      // 點擊增加時增加時數
      form.value.duration++;
    } else {
      // 點擊減少時減少時數 但不可小於每個分類活動的最少預訂時數
      props.categoryDefaultLimitHour < form.duration ? form.value.duration-- : form.value.duration;
    }
    emit("update:bookingDuration", form.value.duration);
  }
  // 預訂時數輸入
  function inputTime(e) {
    if (e.data !== null && (e.data === "-" || e.data === "+" || e.data === "e" || e.data === "E")) {
      form.value.duration = props.categoryDefaultLimitHour;
    }
    if (e.target.value.length > 0) {
      if (e.target.value < props.categoryDefaultLimitHour) {
        form.value.duration = props.categoryDefaultLimitHour;
      }
      if (e.target.value > props.bookingMaxHour) {
        form.value.duration = props.bookingMaxHour;
      }
    }
    // 須更新 props 值 否則下一步付款頁面時 此值會無法同步
    emit("update:bookingDuration", parseInt(form.duration));
  }
  // 預訂時數離開輸入框時
  function inputBlurTime(e) {
    if (e.target.value.length <= 0) {
      form.value.duration = props.categoryDefaultLimitHour;
    }
  }
  // 預設給予預訂時數為 最小預訂時數
  form.value.duration = props.categoryDefaultLimitHour;
  // 判斷當前選擇預訂時數 大於最小預訂時數 且小於 最大可預訂時數 才執行
  if (props.bookingDuration > props.categoryDefaultLimitHour && props.bookingDuration <= props.bookingMaxHour) {
    form.value.duration = props.bookingDuration;
  }
  emit("update:bookingDuration", form.value.duration);
  watch(() => store.getters["orderStore/newBookingTime"], newV => {
    form.value.duration = props.categoryDefaultLimitHour;
  });
  return {
    form,
    chagneTime,
    inputTime,
    inputBlurTime
  };
};
export default __sfc_main;
</script>
