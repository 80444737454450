<template>
    <div>
        <div class="absolute top-[1rem] left-5">
            <span
                v-if="isMobile"
                class="cursor-pointer"
                @click.prevent="$emit('update:showPaymentDetail', false)"
            >
                <font-awesome-icon
                    class="text-sm"
                    :icon="['fas', 'chevron-left']"
                />
            </span>
        </div>
        <div class="p-5 px-10 bg-white">
            <h3 class="font-bold">
                與&nbsp;{{ providerData.name }}&nbsp;一起{{ categoryName }}
            </h3>
            <div class="mt-2 text-xs text-gray-400">
                {{
                    $moment(form.date + form.time, "YYYY-MM-DD HH:mm").format(
                        "YYYY年MM月DD日 HH:mm"
                    )
                }}
                至
                {{
                    $moment(form.date + form.time, "YYYY-MM-DD HH:mm")
                        .add("hours", form.duration)
                        .format("YYYY年MM月DD日 HH:mm")
                }}
            </div>
        </div>
        <div class="p-5 px-10 mt-1 bg-white">
            <div class="flex">
                <h5 class="font-bold">預訂資訊</h5>
            </div>
            <div class="mt-2">
                <div class="flex">
                    <h5 class="mr-2 text-gray-400">活動地點</h5>
                    <div class="flex-1 text-right">
                        {{ form.location }}
                    </div>
                </div>
                <div class="flex">
                    <h5 class="mr-2 text-gray-400">活動內容</h5>
                    <div class="flex-1 text-right">
                        {{ form.description }}
                    </div>
                </div>
            </div>
        </div>
        <div class="p-5 px-10 mt-1 bg-white">
            <div class="flex">
                <h5 class="font-bold">其它</h5>
            </div>
            <div class="mt-2">
                <h5 class="mr-2 text-sm text-gray-400">小費</h5>
                <el-form ref="orderForm" :model="form" :rules="rules">
                    <el-form-item prop="tip">
                        <el-input
                            id="tip"
                            v-model.number="tip"
                            type="number"
                            class="w-full mt-2"
                            placeholder="請輸入自訂金額"
                            @input="
                                handlePrice('tip', tipMinLimit, tipMaxLimit)
                            "
                            @change="
                                handlePrice('tip', tipMinLimit, tipMaxLimit)
                            "
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="flex items-center mt-2 text-gray-400">
                    <div class="flex-1">
                        <span>使用快閃折抵金</span>
                        (<span>餘額 ${{ voucher | formatCurrency }}</span
                        >)
                        <el-popover
                            placement="bottom"
                            width="200"
                            trigger="click"
                        >
                            <template #reference>
                                <span class="text-base cursor-pointer"
                                    ><i class="ml-1 el-icon-warning-outline"></i
                                ></span>
                            </template>
                            <div>
                                快閃折抵金使用說明：<br />
                                1. 訂單滿 $1,000 即可折抵 $100，折抵金額以
                                $1,000 為上限。<br />
                                2.
                                使用快閃折抵金之訂單若取消，則快閃折抵金將全數退還至您的帳戶中。
                            </div>
                        </el-popover>
                    </div>
                    <el-switch
                        id="pay_voucher"
                        v-model="isPayUseVoucher"
                        :disabled="voucher == 0"
                        style="display: block"
                        active-color="#ff4949"
                        inactive-color="#707070"
                    >
                    </el-switch>
                </div>
                <div class="flex items-end mt-2">
                    <label class="flex-1">使用現金付款</label>
                    <el-switch
                        v-model="isCashPay"
                        active-color="#ff4949"
                        inactive-color="#707070"
                    >
                    </el-switch>
                </div>
            </div>
        </div>
        <div class="p-5 px-10 mt-1 bg-white">
            <div class="flex">
                <h5 class="font-bold">價格</h5>
            </div>
            <div class="py-1 mt-2 border-b border-gray-100">
                <div class="flex mb-1">
                    <span class="flex-1 text-gray-400">出席費</span>
                    <span class="OpenSansFont"
                        >${{ categoryPrice | formatCurrency }}</span
                    >
                </div>
                <div class="flex mb-1">
                    <span class="flex-1 text-gray-400">預訂時數</span>
                    <span class="OpenSansFont">x {{ bookingDuration }}</span>
                </div>
                <div class="flex mb-1">
                    <span class="flex-1 text-gray-400">小費</span>
                    <span class="OpenSansFont"
                        >${{ tip | formatCurrency }}</span
                    >
                </div>
            </div>
            <div class="mt-2">
                <div class="flex mb-1">
                    <span class="flex-1 text-gray-400">合計</span>
                    <span class="OpenSansFont"
                        >${{ total | formatCurrency }}</span
                    >
                </div>
                <div v-if="fee > 0" class="flex mb-1">
                    <span class="flex-1 text-gray-400">平台服務費</span>
                    <span class="OpenSansFont"
                        >${{ fee | formatCurrency }}</span
                    >
                </div>
                <div v-if="isPayUseVoucher" class="flex mb-1">
                    <span class="flex-1 text-gray-400">快閃折抵金</span>
                    <span class="OpenSansFont"
                        >-${{ voucherUsePoint | formatCurrency }}</span
                    >
                </div>
                <div class="flex">
                    <span class="flex-1 text-gray-500">總付款金額</span>
                    <span class="font-bold OpenSansFont"
                        >${{ totalPrice | formatCurrency }}</span
                    >
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center px-10 pb-5 bg-white">
            <button
                v-loading="loading"
                :disabled="loading"
                class="mr-2 max-w-[130px] flex-grow cursor-pointer rounded border border-black px-3 py-2 text-sm text-black md:text-base disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-300 disabled:text-white"
                @click.prevent="$emit('update:showPaymentDetail', false)"
            >
                上一步
            </button>
            <button
                v-if="!isCashPay"
                v-loading="loading"
                :disabled="loading || wallet < totalPrice"
                class="md:max-w-[130px] md:flex-grow flex-1 cursor-pointer rounded bg-red-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed disabled:bg-gray-300 md:text-base"
                @click.prevent="onSubmit"
            >
                立即預訂
            </button>
            <button
                v-else
                v-loading="loading"
                :disabled="loading"
                class="md:max-w-[130px] md:flex-grow flex-1 cursor-pointer rounded bg-red-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed disabled:bg-gray-300 md:text-base"
                @click.prevent="onSubmit"
            >
                立即預訂
            </button>
        </div>
        <div
            v-if="totalPrice > wallet"
            class="flex justify-center font-bold text-red-500 OpenSansFont"
        >
            餘額不足：${{ (totalPrice - wallet) | formatCurrency }}
        </div>

        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog
            :showDialog="showDialog"
            :customClass="'md:left-1/4 top-1/4 z-[170]'"
            :customBlackBgClass="'z-[160]'"
            @onCloseDialog="closeDialog"
        >
            <div
                class="md:w-[50%] w-full md:p-10 p-5 bg-white rounded-lg text-sm"
            >
                <div>
                    此時段已無法發起預訂
                    <br />
                    別灰心～您可以嘗試預訂其他時段
                </div>
                <div class="flex justify-center mt-5">
                    <button
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="closeDialog"
                    >
                        重新發起
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import store from "@/store";
import { categoryDescription } from "@/config/categoryConfig.js";
import { isEmpty } from "@/service/anyService";
import { Message } from "element-ui";
import MyDialog from "@/components/Dialog.vue";
const __sfc_main = {};
__sfc_main.props = {
  // 活動每小時單價
  categoryPrice: {
    type: Number,
    required: true
  },
  // 判斷是否顯示預訂資料
  showPaymentDetail: {
    type: Boolean,
    required: true
  },
  // 預訂表單資料
  form: {
    type: Object,
    required: true
  },
  bookingDuration: {
    type: Number
  },
  // 服務商id
  providerId: {
    type: [Number, String]
  },
  // 會員資料
  userData: {
    type: Object,
    default() {
      return {};
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const providerData = ref({
    name: ""
  });
  const {
    proxy
  } = getCurrentInstance();
  const categoryName = ref(categoryDescription[props.form.category_id].name);
  const isDisabled = ref(false);
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  // 判斷是否有開啟平台服務費折抵
  const salesToPanelFee = computed(() => store.state.utilityStore.uiSwitchSettingDatas.enable_sales_to_fee);
  const errorCallback = err => {
    return store.dispatch("apiStore/errorCallback", err);
  };
  // 判斷是否選擇現金付款
  const isCashPay = ref(false);
  // 小費最低額度
  const tipMinLimit = ref(process.env.VUE_APP_TIP_MIN_LIMIT);
  // 小費最高額度
  const tipMaxLimit = ref(process.env.VUE_APP_POINT_MAX_LIMIT);
  // 小費金額
  const tip = ref(0);
  // 判斷是否使用快閃折抵金
  const isPayUseVoucher = ref(false);
  // 顯示預訂失敗 重疊時段彈窗
  const showDialog = ref(false);
  // 快閃折抵金餘額
  const voucher = computed(() => {
    // 判斷沒有 wallet key 給予預設值 0
    if (isEmpty(props.userData.wallet)) {
      return 0;
    } else {
      // 快閃折抵金餘額
      return props.userData.wallet.voucher;
    }
  });
  // 儲值餘額
  const wallet = computed(() => {
    // 判斷沒有 wallet key 給予預設值 0
    if (isEmpty(props.userData.wallet)) {
      return 0;
    } else {
      // 儲值餘額
      return props.userData.wallet.balance;
    }
  });
  // 總計
  const total = computed(() => {
    // 每小時單價 * 時數 加上 小費 計算 total
    let total = props.categoryPrice * props.form.duration + (isEmpty(tip.value) ? 0 : tip.value);
    return Math.ceil(total);
  });
  // 手續費
  const fee = computed(() => {
    // 有加上小費計算方式
    // let total = props.categoryPrice * props.form.duration + (isEmpty(tip.value) ? 0 : tip.value);
    // 未加上小費計算方式
    let total = props.categoryPrice * props.form.duration;
    return salesToPanelFee.value ? 0 : Math.ceil(total * process.env.VUE_APP_PANEL_FEE);
  });
  // 總金額
  const totalPrice = computed(() => {
    return total.value + fee.value - voucherUsePoint.value;
  });

  // 使用快閃折抵金折抵金額
  const voucherUsePoint = computed(() => {
    if (isPayUseVoucher.value && voucher.value > 0) {
      if (total.value >= 1000) {
        const discount = Math.floor(total.value / 1000);
        const result = discount * 100 > voucher.value ? voucher.value : discount * 100;
        if (result > 1000) {
          return 1000;
        } else {
          return result;
        }
      }
    }
    return 0;
  });
  /**
   * 小費最高最小額度限制
   * @param { type Strin or Number(字串或數字) } key 服務項目分類 key
   * @param { type Number(數字) } minPrice 最小額度
   * @param { type Number(數字) } maxPrice
   */
  function handlePrice(key, minPrice, maxPrice) {
    let price = tip.value;
    // 判斷超過小費最大上下額度時直接給予最大上限額度
    if (price > maxPrice) {
      tip.value = parseInt(maxPrice);
      emit("update:form", {
        ...props.form,
        tip: parseInt(maxPrice)
      });
      return;
    }
    emit("update:form", {
      ...props.form,
      tip: price
    });
  }
  tip.value = props.form.tip ?? 0;
  //  判斷使用支付方式
  const paymentChoose = ref({
    // 點數付款
    pointPay: wallet.value >= totalPrice.value ? true : false,
    // 信用卡付差額
    otherPay: wallet.value < totalPrice.value ? true : false,
    // 信用卡付款
    creditCardPay: wallet.value <= 0 ? true : false,
    // 預設信用卡付款
    isCreateCreditCard: wallet.value < totalPrice.value ? true : false,
    // 現金付款
    cashPay: false
  });

  // 取得服務商資料
  async function getProviderData() {
    const {
      data
    } = await proxy.$api.GetProviderDataApi(props.providerId);
    providerData.value = data;
  }
  const rules = ref({
    tip: [{
      validator: formCheckTip,
      trigger: ["blur", "change"]
    }, {
      pattern: /^[0-9]*$/,
      message: `小費需為整數`,
      trigger: "blur"
    }]
  });
  // 判斷小費不可小於 100
  async function formCheckTip(rule, value, callback) {
    // 判斷小費須為整數
    if (typeof parseInt(value) !== "number" && !isEmpty(value)) {
      return callback(new Error("小費須為數字"));
    }
    if (parseInt(value) < tipMinLimit && !isEmpty(parseInt(value))) {
      return callback(new Error(`小費必須高於 $ ${tipMinLimit}，感謝您的支持`));
    }
    if (parseInt(value) > tipMaxLimit && !isEmpty(value)) {
      return callback(new Error(`小費不可高於 $ ${tipMaxLimit}`));
    }
    return callback();
  }
  const orderForm = ref(null);
  // 開立預訂單表單驗證
  async function onSubmit() {
    try {
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await orderForm.value.validate();
      await createOrder();
    } catch (err) {
      console.log(err);
      loading.value = false;
      Message({
        type: "error",
        message: "資訊未填寫正確"
      });
    }
  }
  // 新增預訂單
  async function createOrder() {
    loading.value = true;
    try {
      const form = props.form;
      if (tip.value >= 100) {
        form.tip = tip.value;
      } else {
        delete props.form.tip;
        emit("update:form", {
          ...props.form
        });
        delete form.tip;
      }
      if (isPayUseVoucher.value) {
        form.pay_voucher = 1;
      } else {
        form.pay_voucher = 0;
      }
      // 判斷是否為現金單
      if (isCashPay.value) {
        await proxy.$api.CreateDateByCashOrderApi({
          ...form,
          provider_id: props.providerId,
          user_id: props.userData.id
        });
      } else {
        await proxy.$api.CreateDateOrderApi({
          ...form,
          provider_id: props.providerId,
          user_id: props.userData.id
        });
      }
      // 清空預訂時間
      store.commit("orderStore/setBookingTime", "");
      Message({
        type: "success",
        message: "預訂完成"
      });
      emit("update:showPaymentDetail", false);
      emit("onCreated");
    } catch (err) {
      console.log(err);
      if (err.response.data.error.error === 5002 || err.response.data.error.error === 5003) {
        showDialog.value = true;
      }
      errorCallback({
        err
      });
    } finally {
      loading.value = false;
    }
  }

  // 關閉彈窗
  function closeDialog() {
    showDialog.value = false;
    store.commit("orderStore/setBookingTime", "");
    const form = props.form;
    // 刪除時間 因為該時段已無法預訂
    delete form.time;
    // 刪除日期 因為該日期已無法預訂
    delete form.date;
    emit("update:form", form);
    emit("update:bookingDate", "");
    emit("update:showPaymentDetail", false);
  }
  getProviderData();
  store.dispatch("utilityStore/getClientUiSwitchSetting");
  return {
    providerData,
    categoryName,
    loading,
    isCashPay,
    tipMinLimit,
    tipMaxLimit,
    tip,
    isPayUseVoucher,
    showDialog,
    voucher,
    wallet,
    total,
    fee,
    totalPrice,
    voucherUsePoint,
    handlePrice,
    rules,
    orderForm,
    onSubmit,
    closeDialog
  };
};
__sfc_main.components = Object.assign({
  MyDialog
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    @apply text-gray-500;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    @apply bg-red-500 border-red-500 hidden;
}
::v-deep .el-checkbox__label {
    @apply text-gray-400 pl-0;
}
::v-deep .el-checkbox__input {
    @apply hidden;
}
</style>
