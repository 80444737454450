<template>
    <div
        :class="[
            customLayoutClass,
            isScrollHidden ? 'lg:overflow-x-hidden' : '',
        ]"
        class="flex overflow-x-scroll flex-nowrap whitespace-nowrap"
    >
        <slot name="custom"></slot>
    </div>
</template>

<script>
// 卡片組件

export default {
    props: {
        // 判斷是否隱藏滾動條
        isScrollHidden: {
            type: Boolean,
            default: false,
        },
        // 最外層樣式
        customLayoutClass: {
            type: String,
            default: null,
        },
    },
    methods: {},
};
</script>

<style scoped lang="scss">
/**
客製化滾動條樣式 暫時先隱藏 因此高度跟寬度都不給
*/
::-webkit-scrollbar {
    width: 100px;
    height: 10px;
    @apply border rounded-lg bg-gray-200;
}
/* Track */
::-webkit-scrollbar-track {
    background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
