<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="flex items-center p-5 mb-3 bg-white rounded-lg shadow-lg">
            <div class="grid grid-cols-12 gap-4 w-[100%]">
                <div class="col-span-12">
                    <div class="flex gap-3">
                        <div class="flex-1">
                            <label class="text-gray-400">
                                訂單編號
                                <el-input
                                    v-model="filterOpts.order_id"
                                    size="small"
                                    clearable
                                >
                                </el-input>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                手機
                                <div class="flex items-center">
                                    <div class="mr-2 phone-select">
                                        <el-select
                                            v-model="filterOpts.phone_country"
                                            size="small"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in phoneOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <el-input
                                        v-model="filterOpts.user_phone"
                                        size="small"
                                        clearable
                                    >
                                    </el-input>
                                </div>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                狀態
                                <el-select
                                    v-model="filterOpts.status"
                                    size="small"
                                    class="w-full"
                                    clearable
                                >
                                    <el-option
                                        v-for="(status, index) in orderOptions"
                                        :key="index"
                                        :label="status.label"
                                        :value="status.value"
                                    >
                                        {{ status.label }}
                                    </el-option>
                                </el-select>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                服務商暱稱
                                <el-input
                                    v-model="filterOpts.provider_name"
                                    size="small"
                                    clearable
                                >
                                </el-input>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                會員暱稱
                                <el-input
                                    v-model="filterOpts.user_name"
                                    size="small"
                                    clearable
                                >
                                </el-input>
                            </label>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div class="flex-1">
                            <label class="text-gray-400">
                                成立日期 範圍
                                <el-date-picker
                                    v-model="dateInputsCreate"
                                    style="height: 33px; width: 100%"
                                    size="small"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                開始日期 範圍
                                <el-date-picker
                                    v-model="dateInputsStart"
                                    style="height: 33px; width: 100%"
                                    size="small"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </label>
                        </div>
                        <div class="flex items-end flex-1">
                            <btn
                                color="border border-red-500 text-orange-600 duration-500 hover:bg-red-500 hover:text-white"
                                size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                                customClass="flex-shrink mr-2"
                                @onClick="showDialog = !showDialog"
                                >新增預訂單</btn
                            >
                            <btn
                                color="border border-red-500 bg-red-500 text-white"
                                size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                                customClass="flex-shrink mr-2"
                                @onClick="goSearch"
                            >
                                搜尋
                            </btn>
                            <btn
                                color="border border-black"
                                size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                                customClass="flex-shrink"
                                @onClick="reset"
                            >
                                重置
                            </btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <TableHead
                    ref="TableHead"
                    :labels="tableLabels"
                    @changeSort="changeSort"
                />
                <tbody>
                    <tr
                        v-for="i in datas"
                        :key="i.id"
                        class="text-center transition duration-200"
                    >
                        <td>
                            <div>{{ i.order_id }}</div>
                            <div v-if="i.is_x" class="text-red-500">
                                CityBananaX
                            </div>
                            <div>來源：{{ i.source_application }}</div>
                        </td>
                        <td>{{ i.status | dateStatus }}</td>
                        <td>${{ i.gross_price | formatCurrency }}</td>
                        <td>{{ i.user.name }}</td>
                        <td>{{ i.provider.name }}</td>
                        <td>
                            {{ moment(i.created_at).format("YY/MM/DD HH:mm") }}
                        </td>
                        <td>
                            {{
                                $isEmpty(i.started_at)
                                    ? "服務商到場"
                                    : moment(i.started_at).format(
                                          "YY/MM/DD HH:mm"
                                      )
                            }}
                        </td>
                        <td>
                            <div>
                                {{ i.category ? i.category.name : "" }}
                            </div>
                        </td>
                        <td>{{ i.paid_by === 1 ? "現金" : "線上" }}</td>
                        <td
                            v-permission="['update']"
                            class="cursor-pointer"
                            @click="goEdit(i.id)"
                        >
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-3 mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange"
                />
            </div>
        </div>
        <CreateOrder
            :propsShowDialog="showDialog"
            @onCloseDialog="closeDialog"
        />
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// options
import orderComplaintConfig from "@/config/orderComplaintConfig";
import { categoriesOptions } from "@/config/homeSettingConfig";
import orderConfig from "@/config/orderConfig.js";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// 導入自定義 按鈕
import CreateOrder from "./orders/createOrder/Form.vue";
import btn from "@/components/Button.vue";
import moment from "moment";
// init search Filter options
const defaultFilterOpts = {
    id: "",
    order_id: "",
    user_name: "",
    provider_name: "",
    user_phone: "",
    phone_country: "886",
    created_at_start: "",
    created_at_end: "",
    started_at_start: "",
    started_at_end: "",
    type: "",
    status: "",
    sort_by: "", // created_at or started_at or gross_price
    sort_type: "", // asc or desc
};
export default {
    name: "orderManageList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
        CreateOrder,
        btn,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            const result = {
                ...filter,
                phone: this.filterOpts.user_phone
                    ? this.filterOpts.phone_country + this.filterOpts.user_phone
                    : null,
                phone: this.filterOpts.user_phone
                    ? this.filterOpts.phone_country + this.filterOpts.user_phone
                    : null,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };

            if (!this.filterOpts.user_phone) {
                delete result.user_phone;
            }

            return result;
        },
        // 問題 options
        problemOptions() {
            const status = orderComplaintConfig.complaintType;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 活動 options
        categoriesOptions() {
            return categoriesOptions;
        },
        // 狀態
        statusOption() {
            const status = orderComplaintConfig.complaintStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 訂單狀態 options
        orderOptions() {
            const status = orderConfig.orderStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        moment() {
            return moment;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts }, // 篩選options
            dateInputsCreate: [], // 時間範圍
            dateInputsStart: [], // 時間範圍
            // table header
            tableLabels: [
                {
                    label: "訂單編號",
                },
                {
                    label: "狀態",
                },
                {
                    label: "總額",
                },
                {
                    label: "會員暱稱",
                },
                {
                    label: "服務商暱稱",
                },
                {
                    label: "成立時間",
                    isSort: true,
                    val: "created_at",
                },
                {
                    label: "開始時間",
                    isSort: true,
                    val: "started_at",
                },
                {
                    label: "類型",
                },
                {
                    label: "付款類型",
                },
                {
                    label: "查看",
                },
            ],
            showDialog: false,
            // 手機國碼
            phoneOptions() {
                return phoneOptions;
            },
        };
    },
    methods: {
        // 讀取 list api
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchAllDatings(this.filterData)
                // .customerComplaintApi(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.dateInputsCreate = [];
            this.dateInputsStart = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 到訂單細節頁,將此頁資訊帶入
        goEdit(id) {
            const userID = 0;
            const tab = "provider_update";
            const pre = "OrderManageList";
            this.$router.push({
                name: "order_detail",
                params: { tab, pre, orderID: id, userID },
            });
        },
        // 關閉彈窗
        closeDialog(val) {
            this.showDialog = val;
            this.getList();
        },
    },
    watch: {
        dateInputsCreate(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_at_start = "";
                this.filterOpts.created_at_end = "";
                return;
            }
            this.filterOpts.created_at_start = val[0];
            this.filterOpts.created_at_end = val[1];
        },
        dateInputsStart(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.started_at_start = "";
                this.filterOpts.started_at_end = "";
                return;
            }
            this.filterOpts.started_at_start = val[0];
            this.filterOpts.started_at_end = val[1];
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
