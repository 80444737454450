<template>
    <el-dialog
        :visible.sync="showDialog"
        :close-on-click-modal="true"
        :modal-append-to-body="true"
        :close-on-press-escape="true"
        @open="openDialog"
        @close="closeDialog"
    >
        <div class="flex justify-center">
            <div v-if="!showPaymentDetail" class="w-full">
                <div>
                    <div
                        class="py-5 text-2xl font-bold text-center text-white bg-black"
                    >
                        發起預訂
                    </div>
                    <el-form
                        ref="userFormDom"
                        :model="userForm"
                        :rules="userFormRules"
                    >
                        <el-form-item v-loading="loading" prop="mobile">
                            <label>會員手機</label>
                            <div class="flex">
                                <el-select
                                    v-model="userForm.countryCode"
                                    class="flex-shrink w-40"
                                    placeholder="國碼"
                                >
                                    <el-option
                                        v-for="item in ['886']"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                    </el-option>
                                </el-select>
                                <el-input
                                    v-model="userForm.mobile"
                                    class="flex-grow"
                                    placeholder="請輸入會員手機號碼"
                                    @input="onInput"
                                >
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                    <el-form
                        ref="providerFormDom"
                        :model="providerForm"
                        :rules="providerFormRules"
                    >
                        <el-form-item v-loading="loading" prop="mobile">
                            <label>服務商手機</label>
                            <div class="flex">
                                <el-select
                                    v-model="providerForm.countryCode"
                                    class="flex-shrink w-40"
                                    placeholder="國碼"
                                >
                                    <el-option
                                        v-for="item in ['886']"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                    </el-option>
                                </el-select>
                                <el-input
                                    v-model="providerForm.mobile"
                                    class="flex-grow"
                                    placeholder="請輸入會員手機號碼"
                                    @input="onInput"
                                >
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                    <h5
                        v-if="
                            Object.keys(userData).length > 0 &&
                            Object.keys(providerData).length > 0
                        "
                        class="text-xl text-center"
                    >
                        開單會員：<strong>{{ userData.name }}</strong>
                    </h5>
                    <h5
                        v-if="
                            Object.keys(userData).length > 0 &&
                            Object.keys(providerData).length > 0
                        "
                        class="text-xl text-center"
                    >
                        預訂服務商：<strong>{{ providerData.name }}</strong>
                    </h5>
                    <div
                        v-if="
                            Object.keys(userData).length > 0 &&
                            Object.keys(providerData).length > 0
                        "
                    >
                        <OrderCategory
                            :categoryId.sync="categoryId"
                            :categoryPrice.sync="categoryPrice"
                            :categoryDefaultLimitHour.sync="
                                categoryDefaultLimitHour
                            "
                            :bookingDate.sync="bookingDate"
                            :providerId="providerData.banana_id"
                        />
                        <OrderBookingDateTime
                            v-if="categoryId"
                            :categoryId="categoryId"
                            :bookingDate.sync="bookingDate"
                            :bookingMaxHour.sync="bookingMaxHour"
                            :providerId="providerData.banana_id"
                        />
                        <OrderHours
                            v-if="
                                bookingDate &&
                                bookingTime &&
                                typeof bookingMaxHour === 'number'
                            "
                            :categoryPrice="categoryPrice"
                            :categoryDefaultLimitHour.sync="
                                categoryDefaultLimitHour
                            "
                            :bookingMaxHour.sync="bookingMaxHour"
                            :bookingDuration.sync="bookingDuration"
                        />
                        <OrderComment
                            v-if="bookingDate && bookingTime"
                            :bookingDistrict.sync="bookingDistrict"
                            :bookingLocation.sync="bookingLocation"
                            :bookingDescription.sync="bookingDescription"
                        />

                        <div class="p-5 mt-2 bg-white md:mx-3 md:rounded-lg">
                            <div class="flex items-center justify-end">
                                <div
                                    v-if="categoryPrice * bookingDuration > 0"
                                    class="flex-1 text-xl font-bold text-red-500 OpenSansFont"
                                >
                                    <span
                                        >$
                                        {{
                                            (categoryPrice * bookingDuration)
                                                | formatCurrency
                                        }}</span
                                    >
                                </div>
                                <!-- <button @click.prevent=""
                            class="mr-2 max-w-[130px] flex-grow cursor-pointer rounded border border-black px-3 py-2 text-sm text-black md:text-base">取消</button> -->
                                <button
                                    :disabled="checkDisabled(form)"
                                    class="ml-2 max-w-[130px] flex-grow cursor-pointer rounded bg-red-500 px-3 py-2 text-sm text-white disabled:cursor-not-allowed disabled:bg-gray-300 md:text-base"
                                    @click.prevent="nextTo"
                                >
                                    下一步
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else ref="orderContentRef" class="max-w-[800px] w-full">
                <OrderPayment
                    :categoryPrice.sync="categoryPrice"
                    :showPaymentDetail.sync="showPaymentDetail"
                    :bookingDuration.sync="bookingDuration"
                    :bookingDate.sync="bookingDate"
                    :userData="userData"
                    :providerId="providerData.banana_id"
                    :form.sync="form"
                    @onCreated="closeDialog"
                />
            </div>
        </div>
    </el-dialog>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 導入 google map autocomplete 組件
import GoogleMapAutoComplete from "@/components/GoogleMapAutoComplete.vue";
import moment from "moment";
import store from "@/store";
// 導入表單判斷是否有值方法
import { checkFormData } from "@/service/anyService";
import OrderCategory from "../createOrder/components/OrderCategory.vue";
import OrderBookingDateTime from "../createOrder/components/OrderBookingDateTime.vue";
import OrderHours from "../createOrder/components/OrderHours.vue";
import OrderComment from "../createOrder/components/OrderComment.vue";
import OrderPayment from "../createOrder/components/OrderPayment.vue";
const __sfc_main = {};
__sfc_main.props = {
  // 顯示彈窗
  propsShowDialog: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const router = useRouter();
  const {
    proxy
  } = getCurrentInstance();
  const emit = __ctx.emit;
  const props = __props;
  const loading = ref(false);
  const showDialog = ref(false);
  //  訂單 dom
  const orderContentRef = ref(null);
  // 分類 id
  const categoryId = ref(null);
  // 每小時單價
  const categoryPrice = ref(0);
  // 預訂活動最少時數
  const categoryDefaultLimitHour = ref(0);
  // 活動日期
  const bookingDate = ref("");
  // 活動時間
  const bookingTime = computed(() => store.state.orderStore.bookingTime);
  // 活動最大預訂時數
  const bookingMaxHour = ref(0);
  // 活動時數
  const bookingDuration = ref(0);
  // 活動區域
  const bookingDistrict = ref("");
  // 活動地點
  const bookingLocation = ref("");
  // 活動備註
  const bookingDescription = ref("");
  // 判斷按鈕是否 disabled
  const isDisabled = ref(true);
  // 判斷是否顯示 預付款資訊
  const showPaymentDetail = ref(false);
  // 判斷是否顯示 活動表單詳情頁
  const showConfirmDetail = ref(false);
  let userForm = ref({
    countryCode: "886",
    mobile: ""
  });
  const userFormRules = ref({
    mobile: [{
      required: true,
      message: "輸入搜尋手機號碼",
      trigger: "blur"
    }, {
      pattern: /^[0-9]*$/,
      message: `手機號碼必須為數字`,
      trigger: "blur"
    }, {
      validator: formPhoneSearchUser,
      trigger: "blur"
    }]
  });
  let providerForm = ref({
    countryCode: "886",
    mobile: ""
  });
  const providerFormRules = ref({
    mobile: [{
      required: true,
      message: "輸入搜尋手機號碼",
      trigger: "blur"
    }, {
      pattern: /^[0-9]*$/,
      message: `手機號碼必須為數字`,
      trigger: "blur"
    }, {
      validator: formPhoneSearchProvider,
      trigger: "blur"
    }]
  });
  const userData = ref({});
  const providerData = ref({});

  // 使用手機號碼搜尋會員
  async function formPhoneSearchUser(rule, value, callback) {
    // 判斷信箱是否為空值 或 信箱格式是否正確
    if (value !== "") {
      if (value.length > 0 && value[0] == 0) {
        userForm.value.mobile = value.substring(1);
      }
      loading.value = true;
      try {
        const {
          data
        } = await proxy.$api.UserSearchByPhone(userForm.value.countryCode + userForm.value.mobile);
        if (data.role > 0) {
          // 取消旋轉動畫
          loading.value = false;
          return callback(new Error("該號碼不是會員"));
        }
        userData.value = data;
        // 取消旋轉動畫
        loading.value = false;
        return callback();
      } catch (err) {
        userData.value = {};
        // 取消旋轉動畫
        loading.value = false;
        return callback(new Error("找不到用戶"));
      }
    } else {
      // 取消旋轉動畫
      loading.value = false;
      return callback(new Error("輸入搜尋手機號碼"));
    }
  }

  // 使用手機號碼搜尋服務商
  async function formPhoneSearchProvider(rule, value, callback) {
    // 判斷信箱是否為空值 或 信箱格式是否正確
    if (value !== "") {
      if (value.length > 0 && value[0] == 0) {
        providerForm.value.mobile = value.substring(1);
      }
      loading.value = true;
      try {
        const {
          data
        } = await proxy.$api.UserSearchByPhone(providerForm.value.countryCode + providerForm.value.mobile);
        if (data.role <= 0) {
          // 取消旋轉動畫
          loading.value = false;
          return callback(new Error("該號碼不是服務商"));
        }
        providerData.value = data;
        // 取消旋轉動畫
        loading.value = false;
        return callback();
      } catch (err) {
        providerData.value = {};
        // 取消旋轉動畫
        loading.value = false;
        return callback(new Error("找不到用戶"));
      }
    } else {
      // 取消旋轉動畫
      loading.value = false;
      return callback(new Error("輸入搜尋手機號碼"));
    }
  }

  // 輸入中檢查
  function onInput(val) {
    // 當手機號碼欄位為空
    if (this.$isEmpty(val)) {
      this.disabled = true;
    }
    // 當手機號碼欄位非數字時
    if (!val.match(/^[0-9]*$/)) {
      this.disabled = true;
    }
  }

  // 表單 dom
  const formRefDom = ref(null);

  // 活動訂單資料
  const form = ref({
    category_id: null,
    district: "",
    location: "",
    description: "",
    date: "",
    time: "",
    duration: null,
    provider_id: ""
  });
  function closeDialog() {
    showConfirmDetail.value = false;
    userData.value = {};
    providerData.value = {};
    userForm.value.mobile = "";
    providerForm.value.mobile = "";
    form.value = {
      category_id: null,
      district: "",
      location: "",
      description: "",
      date: "",
      time: "",
      duration: null,
      provider_id: ""
    };
    store.commit("orderStore/setBookingTime", "");
    bookingDuration.value = 0;
    bookingDate.value = "";
    bookingDistrict.value = "";
    bookingDescription.value = "";
    bookingLocation.value = "";
    categoryId.value = null;
    emit("onCloseDialog", false);
  }

  // 新增點擊返回時 將瀏覽器視窗置頂機制
  watch(() => showConfirmDetail.value, newV => {
    if (newV === false) {
      window.scrollTo(0, 0);
    }
  });
  watch(() => props.propsShowDialog, newV => {
    showDialog.value = newV;
  });
  // 切換至付款畫面
  function nextTo() {
    showPaymentDetail.value = true;
    // 判斷是瀏覽器時才執行
    if (process.client) {
      // 將視窗滾回最頂部
      window.scrollTo(0, 0);
    }
  }

  // 判斷是否要 disabled
  function checkDisabled(formValue) {
    const formKeys = ["category_id", "district", "location", "description", "date", "time", "duration"];
    return checkFormData(formKeys, formValue);
  }
  function openDialog() {
    form.value = {
      description: "",
      started_at_time: "",
      duration: 2,
      provider_required: 1,
      hourly_pay: 2000
    };
  }
  watch(bookingTime, newV => {
    form.value.time = newV;
  });
  watch(categoryId, (newV, oldV) => {
    form.value.category_id = newV;
  });
  watch(bookingDate, (newV, oldV) => {
    form.value.date = newV;
  });
  watch(bookingLocation, (newV, oldV) => {
    form.value.location = newV;
  });
  watch(bookingDistrict, (newV, oldV) => {
    form.value.district = newV;
  });
  watch(bookingDescription, (newV, oldV) => {
    form.value.description = newV;
  });
  watch(bookingDuration, (newV, oldV) => {
    form.value.duration = newV;
  });
  watch(form, (newV, oldV) => {
    const formKeys = ["category_id", "district", "location", "description", "date", "time", "duration"];
    isDisabled.value = checkFormData(formKeys, newV);
  });
  window.scrollTo(0, 0);
  return {
    loading,
    showDialog,
    orderContentRef,
    categoryId,
    categoryPrice,
    categoryDefaultLimitHour,
    bookingDate,
    bookingTime,
    bookingMaxHour,
    bookingDuration,
    bookingDistrict,
    bookingLocation,
    bookingDescription,
    showPaymentDetail,
    userForm,
    userFormRules,
    providerForm,
    providerFormRules,
    userData,
    providerData,
    onInput,
    form,
    closeDialog,
    nextTo,
    checkDisabled,
    openDialog
  };
};
__sfc_main.components = Object.assign({
  OrderCategory,
  OrderBookingDateTime,
  OrderHours,
  OrderComment,
  OrderPayment
}, __sfc_main.components);
export default __sfc_main;
</script>
