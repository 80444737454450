export const categoryDescription = {
    1: {
        name: "吃喝玩樂",
        headerDescription:
            "這裡的快閃服務商，能夠參與你生活中的各種休閒活動，與你談天說笑，出席合適的場合，在城市中追求美好時刻。",
        optionDescription: "參與你生活中的各種休閒活動，出席合適的場合。",
        settingDescription: "生活中的各種休閒活動，出席合適的場合。",
    },
    2: {
        name: "飲酒享樂",
        headerDescription:
            "這裡的快閃服務商，能夠參與你生活中的飲酒活動，與你把酒言歡，出席合適的場合，在城市中追求美好時刻。",
        optionDescription: "參與你生活中的飲酒活動，出席合適的場合。",
        settingDescription: "生活中的飲酒活動，出席合適的場合。",
    },
    3: {
        name: "雲遊漫旅",
        headerDescription:
            "這裡的快閃服務商，能參與你較長時間或到外縣市的活動，與你四處探索，出席合適的場合，在城市中追求美好時刻。",
        optionDescription: "參與你較長時間或到外縣市的活動，出席合適的場合。",
        settingDescription: "較長時間或到外縣市的活動，出席合適的場合。",
    },
    4: {
        name: "工商服務",
        headerDescription:
            "這裡的快閃服務商，能夠參與你的商業活動，出席合適的場合，與你共創佳績，在城市中追求美好時刻。",
        optionDescription: "參與你的商業活動，出席合適的場合。",
        settingDescription: "現場商業活動，出席合適的場合。",
    },
    5: {
        name: "專業諮詢",
        headerDescription:
            "會面諮詢服務商的擅長處及經驗，得到解答或建議，且能夠以有意義的話題做為活動與交流的開端。",
        optionDescription: "與服務商會面諮詢擅長處及經驗。",
        settingDescription: "提供擅長處及經驗的諮詢。",
    },
};
