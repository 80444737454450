<template>
    <div class="p-5 mx-3 -mt-10 bg-white rounded-lg md:mt-5">
        <div class="flex">
            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
            <h5 class="font-bold">選擇活動</h5>
            <p class="flex-1 text-right text-red-500">
                ${{ currentCategory.price }}/小時
            </p>
        </div>
        <ul class="flex mt-5 overflow-x-scroll whitespace-nowrap">
            <li v-for="(item, index) in categories" :key="index" class="ml-2">
                <div>
                    <button
                        :class="
                            currentCategory.category_id ===
                            item.pivot.category_id
                                ? 'bg-red-500 border-red-500 text-white'
                                : 'bg-white border-gray-300 text-black'
                        "
                        class="px-2 py-1 text-xs border rounded-lg"
                        @click.prevent="
                            changeCategory({
                                category_id: item.pivot.category_id,
                                price: item.pivot.price,
                                hour: item.pivot.min_dating_unit,
                            })
                        "
                    >
                        {{ item.name }}
                    </button>
                </div>
            </li>
        </ul>
        <div
            v-if="currentCategory.category_id"
            class="mx-2 mt-2 text-xs text-gray-400"
        >
            {{
                categoryDescription[currentCategory.category_id]
                    .optionDescription
            }}
        </div>
        <div
            v-if="currentCategory.category_id"
            class="mb-5 text-xs text-gray-400"
        >
            * 此活動項目時數至少為 {{ categoryDefaultLimitHour }} 小時
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { categoryDescription } from "@/config/categoryConfig";
import store from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  // 活動分類 id
  categoryId: {
    type: [Number, String]
  },
  // 分類最少預訂時數
  categoryDefaultLimitHour: {
    type: Number
  },
  // 服務商id
  providerId: {
    type: [Number, String]
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const props = __props;
  const loading = ref(false);
  const categories = ref([]);
  const route = useRoute();
  const emit = __ctx.emit;
  const errorCallback = async err => {
    await store.dispatch("apiStore/errorCallback", err);
  };
  const currentCategory = ref({
    category_id: null,
    price: 0
  });
  // 取得分類列表
  async function getCategoriesList() {
    loading.value = true;
    try {
      let {
        data
      } = await proxy.$api.GetProviderDataApi(props.providerId);
      // 設定服務商分類資料
      categories.value = data.normal_categories;
      // 設定服務商名稱
      store.commit("providerStore/setProviderName", data.name);
    } catch (err) {
      await errorCallback({
        err
      });
    } finally {
      loading.value = false;
    }
  }
  // 點擊分類
  function changeCategory(value) {
    currentCategory.value = value;
    emit("update:categoryId", value.category_id);
    emit("update:categoryPrice", value.price);
    emit("update:categoryDefaultLimitHour", value.hour);
    emit("update:bookingDate", "");
    store.commit("orderStore/setBookingTime", "");
  }
  onMounted(async () => {
    await getCategoriesList();
    // 判斷有傳入選中分類 id 時觸發 通常會觸發情境為 填完預訂單後返回上一步時
    const category = categories.value.find(item => {
      return item.id == props.categoryId;
    });
    // 當有找到對應的分類選項時 給予預設分類值
    if (category !== undefined) {
      currentCategory.value = {
        category_id: category.id,
        price: category.pivot.price,
        hour: category.pivot.min_dating_unit
      };
      return;
    }
  });
  watch(() => props.categoryId, (newV, oldV) => {
    const category = categories.value.find(item => item.id == newV);
  });
  return {
    categoryDescription,
    categories,
    currentCategory,
    changeCategory
  };
};
export default __sfc_main;
</script>

<style lang="scss" scoped>
/**
客製化滾動條樣式 暫時先隱藏 因此高度跟寬度都不給
*/
::-webkit-scrollbar {
    width: 0;
    height: 0;
} /* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
    //   background-color: #b46868;
} /* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
    //   background-color: rgba(0, 0, 0, 0.2);
} /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
    //   background-color: #7c2929;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
    //   background-color: black;
}
</style>
