<template>
    <div
        v-loading="loading"
        class="p-5 mx-3 mt-1 bg-white rounded-lg"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
    >
        <div class="flex">
            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
            <h5 class="font-bold">預訂日期與時間</h5>
        </div>
        <div class="mt-5 text-sm text-gray-400">
            可預訂日期
            <span v-if="form.date"
                >| {{ $moment(form.date).format("MM") }}月</span
            >
        </div>
        <Carousel id="dates" class="mt-2" :datas="dates" :isCardType="false">
            <template #custom>
                <div
                    v-for="(item, key) in dates"
                    :id="item"
                    :key="key"
                    class="min-w-[14%] max-w-[14%]"
                >
                    <button
                        :disabled="$isEmpty(datingAvailableTimes[item])"
                        :class="
                            item === form.date
                                ? 'border border-red-500 bg-red-500 text-white'
                                : 'border border-black'
                        "
                        class="flex items-center justify-center px-2 py-2 mx-1 rounded-md disabled:cursor-not-allowed disabled:border-gray-400 disabled:bg-gray-100 disabled:text-gray-700 md:px-5"
                        @click.prevent="onSelectDay(item)"
                    >
                        <div>
                            <div
                                class="mb-1 text-base font-bold leading-none OpenSansFont"
                            >
                                {{ $moment(item).format("DD") }}
                            </div>
                            <div class="text-xs font-light leading-none">
                                {{ $moment(item).format("dd") }}
                            </div>
                        </div>
                    </button>
                </div>
            </template>
        </Carousel>
        <div class="mt-5 text-sm text-gray-400">開始時間</div>
        <ul
            v-if="form.date !== undefined"
            class="flex flex-wrap mt-2 list-none"
        >
            <li
                v-for="(item, key) in datingAvailableTimes[form.date]"
                :key="key"
                class="px-2 m-1 rounded-md cursor-pointer"
                :class="[
                    key === form.time
                        ? 'border border-red-500 bg-red-500 text-white'
                        : 'border border-black',
                ]"
                @click="
                    onSelectHour({
                        time: key,
                        hourRange: datingAvailableTimes[form.date][key],
                    })
                "
            >
                {{ key }}
            </li>
        </ul>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { isEmpty } from "@/service/anyService";
// 導入幻燈片
import Carousel from "@/components/Carousel.vue";
import { max as _max } from "lodash/math";
import store from "@/store";
const __sfc_main = {};
__sfc_main.props = {
  // 活動分類 id
  categoryId: {
    type: [Number, String],
    required: true
  },
  // 預訂日期
  bookingDate: {
    type: String,
    default: null
  },
  // 服務商id
  providerId: {
    type: [Number, String]
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    proxy
  } = getCurrentInstance();
  const route = useRoute();
  const loading = ref(false);
  const dates = ref([]);
  const emit = __ctx.emit;
  const datingAvailableTimes = ref({});
  // 預訂時間
  const bookingTime = computed(() => store.state.orderStore.bookingTime);
  const errorCallback = err => {
    return store.dispatch("apiStore/errorCallback", err);
  };
  const form = ref({
    date: null,
    time: null
  });
  /**
   * 取得可約會的時間
   * @param { type String(字串) } providerId 服務商id
   * @param { type String or Number(字串或數字) } categoryId 分類 id
   */
  async function getDatingAvailableTimes(providerId, categoryId) {
    loading.value = true;
    try {
      let {
        data
      } = await proxy.$api.GetCategoryDatingAvailableTimesApi(providerId, categoryId);
      loading.value = false;
      // 將可選日期 存入陣列
      Object.keys(data).forEach(objKey => {
        // 判斷是否有重複日期
        if (dates.value.findIndex(item => item === objKey) === -1) {
          dates.value.push(objKey);
        }
      });
      // 可預訂時間資料
      datingAvailableTimes.value = data;
    } catch (err) {
      console.log(err);
      loading.value = false;
      errorCallback({
        err
      });
    }
  }

  /**
   * 選擇時間事件
   * @param { type String(字串) } val 日期
   */
  function onSelectDay(val) {
    form.value.date = val;
    emit("update:bookingDate", val);
    form.value.time = "";
    store.commit("orderStore/setBookingTime", "");
  }

  // 跳轉到指定日期位置
  function scrollbarToDayPart() {
    // scrollbar x 軸 位移至選擇的日期位子
    nextTick(() => {
      if (!isEmpty(form.value.date)) {
        // 取得被選中的日期 dom
        const dom = document.getElementById(form.value.date);
        // 取得選擇日期區間 dom
        const datesDom = document.getElementById("dates");
        // scorllbar x 軸 位移至選中的 日期 位置
        datesDom.scrollTo(dom.offsetLeft, 0);
      }
    });
  }
  /**
   * 選擇時間事件
   * @param { type String(字串) } val 時間
   */
  function onSelectHour({
    time,
    hourRange
  }) {
    form.value.time = time;
    store.commit("orderStore/setBookingTime", time);
    const maxHour = _max(hourRange);
    emit("update:bookingMaxHour", maxHour);
  }
  onMounted(async () => {
    await getDatingAvailableTimes(props.providerId, props.categoryId);
    // 判斷可選日期中有目前 props.bookingDate 日期時才觸發
    if (dates.value.find(date => date === props.bookingDate) !== undefined) {
      form.value.date = props.bookingDate;
      // 判斷可選時間中有目前 store 中 bookingTime 日期時才觸發
      if (Object.keys(datingAvailableTimes.value[form.value.date]).find(time => time === store.state.orderStore.bookingTime) !== undefined) {
        form.value.time = store.state.orderStore.bookingTime;
      }
    }
    // 選擇日期 scrollbar x 軸 位移事件
    scrollbarToDayPart();
  });
  watch(() => props.categoryId, async (newV, oldV) => {
    await getDatingAvailableTimes(props.providerId, newV);
  }, {
    deep: true
  });
  watch(() => props.bookingDate, (newV, oldV) => {
    form.value.date = newV;
  }, {
    deep: true
  });
  watch(bookingTime, newV => {
    form.value.time = newV;
  });
  return {
    loading,
    dates,
    datingAvailableTimes,
    form,
    onSelectDay,
    onSelectHour
  };
};
__sfc_main.components = Object.assign({
  Carousel
}, __sfc_main.components);
export default __sfc_main;
</script>
