var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-5 mx-3 -mt-10 bg-white rounded-lg md:mt-5"},[_c('div',{staticClass:"flex"},[_c('h5',{staticClass:"font-bold"},[_vm._v("選擇活動")]),_c('p',{staticClass:"flex-1 text-right text-red-500"},[_vm._v(" $"+_vm._s(_vm.currentCategory.price)+"/小時 ")])]),_c('ul',{staticClass:"flex mt-5 overflow-x-scroll whitespace-nowrap"},_vm._l((_vm.categories),function(item,index){return _c('li',{key:index,staticClass:"ml-2"},[_c('div',[_c('button',{staticClass:"px-2 py-1 text-xs border rounded-lg",class:_vm.currentCategory.category_id ===
                        item.pivot.category_id
                            ? 'bg-red-500 border-red-500 text-white'
                            : 'bg-white border-gray-300 text-black',on:{"click":function($event){$event.preventDefault();return _vm.changeCategory({
                            category_id: item.pivot.category_id,
                            price: item.pivot.price,
                            hour: item.pivot.min_dating_unit,
                        })}}},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0),(_vm.currentCategory.category_id)?_c('div',{staticClass:"mx-2 mt-2 text-xs text-gray-400"},[_vm._v(" "+_vm._s(_vm.categoryDescription[_vm.currentCategory.category_id] .optionDescription)+" ")]):_vm._e(),(_vm.currentCategory.category_id)?_c('div',{staticClass:"mb-5 text-xs text-gray-400"},[_vm._v(" * 此活動項目時數至少為 "+_vm._s(_vm.categoryDefaultLimitHour)+" 小時 ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }