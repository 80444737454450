<template>
    <div class="p-5 mx-3 mt-1 bg-white rounded-lg">
        <div class="flex">
            <!-- <div class="rounded-full bg-red-500 min-w-[8px] mr-5"></div> -->
            <h5 class="font-bold">預訂資訊</h5>
        </div>
        <div class="mt-2">
            <div>
                <div class="text-sm text-gray-400">
                    會面城市與地點（建議選擇公開場合）
                    <span class="text-red-500">*</span>
                </div>
                <GoogleMapAutoComplete
                    class="relative w-full"
                    :form.sync="form"
                    :textareaClass="'mt-1'"
                    :bookingDistrict.sync="bookingDistrict"
                    :bookingLocation.sync="bookingLocation"
                    placement="bottom"
                />
            </div>
            <div class="mt-5">
                <div class="text-sm text-gray-400">
                    請註明活動內容 <span class="text-red-500">*</span>
                </div>
                <el-input
                    v-model="form.description"
                    :placeholder="orderDescriptionPlaceholder"
                    type="textarea"
                    rows="5"
                    class="mt-1"
                    @keypress.native.capture="onKeypressOrPaste"
                    @paste.native.capture="onKeypressOrPaste"
                ></el-input>
                <div class="text-right">
                    <span
                        v-if="!$isEmpty(form.description)"
                        :class="
                            form.description.length > inputLimit
                                ? 'text-orange-600'
                                : ''
                        "
                        >{{ form.description.length }}/{{ inputLimit }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 導入 google map 搜尋關鍵字地點模組
import GoogleMapAutoComplete from "@/components/GoogleMapAutoComplete.vue";
const __sfc_main = {};
__sfc_main.props = {
  bookingDistrict: {
    type: String
  },
  bookingLocation: {
    type: String
  },
  bookingDescription: {
    type: String
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  //  訂單描述輸入匡 placeholder
  const orderDescriptionPlaceholder = ref(process.env.VUE_APP_ORDER_DESCRIPTION_PLACEHOLDER);
  // 字數限制
  const inputLimit = ref(process.env.VUE_APP_INPUT_MIN_LIMIT);
  const form = ref({
    district: "",
    location: "",
    description: ""
  });
  // 當輸入文字時或複製貼上時 觸發事件 當輸入文字超過 字數限制 則不能輸入或貼上
  function onKeypressOrPaste(e) {
    // 判斷是否大於目前限定最大字數
    if (form.description.length >= process.env.INPUT_MIN_LIMIT) {
      // 當超過字數時 不給輸入
      e.preventDefault();
    }
  }
  onMounted(() => {
    form.value.district = props.bookingDistrict;
    form.value.location = props.bookingLocation;
    form.value.description = props.bookingDescription;
  });
  watch(() => form.value.district, (newV, oldV) => {
    emit("update:bookingDistrict", newV);
  });
  watch(() => form.value.location, (newV, oldV) => {
    emit("update:bookingLocation", newV);
  });
  watch(() => form.value.description, (newV, oldV) => {
    emit("update:bookingDescription", newV);
  });
  return {
    orderDescriptionPlaceholder,
    inputLimit,
    form,
    onKeypressOrPaste
  };
};
__sfc_main.components = Object.assign({
  GoogleMapAutoComplete
}, __sfc_main.components);
export default __sfc_main;
</script>
